import React, { FC } from "react";
import "./SubMenu.module.scss";
import { Item } from "../../../hooks/Menu/menu.prop";
import MenuOption from "../MenuOption";

const SubMenu: FC<{ item: Item; count: number }> = ({ item, count }) => {
  return (
    <ul className="sub-menu">
      {item.items?.map((subitem, index) => (
        <MenuOption item={subitem} key={`${count}-${index}`}>
          {(subitem.items?.length ?? 0) > 0 && (
            <SubMenu item={subitem} count={count + 1} />
          )}
        </MenuOption>
      ))}
    </ul>
  );
};

export default SubMenu;
