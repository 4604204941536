import { MenuProps } from "./../../../../hooks/Menu/menu.prop";

export const MENU_FOOTER: MenuProps[] = [
  {
    title: "Producto",
    position: "vertical",
    menu: [
      {
        title: "Beneficios",
      },
      {
        title: "Tarifas",
      },
      {
        title: "Partners",
      },
    ],
  },
  {
    title: "Soporte",
    position: "vertical",
    menu: [
      {
        title: "Términos de servicio",
      },
      {
        title: "Ayuda",
      },
      {
        title: "Contacto",
      },
    ],
  },
  {
    title: "Integraciones",
    position: "vertical",
    menu: [
      {
        title: "Wordpress",
      },
      {
        title: "Shopify",
      },
      {
        title: "API",
      },
    ],
  },
  {
    title: "Síguenos",
    position: "vertical",
    menu: [
      {
        title: "Facebook",
      },
      {
        title: "Instagram",
      },
      {
        title: "Youtube",
      },
    ],
  },
];
