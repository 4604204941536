import React, { FC } from "react";
import { ButtonProps } from "../../hooks/Button/button.prop";
import "./Button.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomButton: FC<ButtonProps> = ({
  img,
  icon,
  text,
  style,
  ...props
}) => {
  return (
    <button
      className={`button ${(icon || img) && !text ? "button--icon" : ""} ${
        style ? `button--${style}` : ""
      }`}
      {...props}
    >
      {img && <img src={img} alt="icon" className="button__icon" />}
      {icon && <FontAwesomeIcon icon={icon} className="button__icon" />}
      {text && <span>{text}</span>}
    </button>
  );
};

export default CustomButton;
