import { MenuProps } from "../../../../hooks/Menu/menu.prop";

export const MENU_HEADER: MenuProps = {
  position: "horizontal",
  menu: [
    {
      title: "Inicio",
      to: '/'
    },
    {
      title: "Beneficios",
      to: '#beneficios'
    },
    {
      title: "Documentacion",
      to: '/Docs'
    },
    {
      title: "Contacto",
      to: '#contacto'
    },
    // {
    //   title: "Precios",
    // },
    // {
    //   title: "Documentación",
    // },
    // {
    //   title: "Contacto",
    // },
  ],
};
