import { Link } from "gatsby";
import React, { FC } from "react";
import "./Menu.module.scss";
import SubMenu from "./SubMenu";
import { MenuProps } from "../../hooks/Menu/menu.prop";
import MenuOption from "./MenuOption";

const Menu: FC<MenuProps> = ({ position, menu }) => {
  return (
    <>
      <nav className={`menu menu--${position ?? "vertical"}`}>
        <ul className="options">
          {menu.map((item, index) => (
            <MenuOption item={item} key={index}>
              {" "}
              {(item.items?.length ?? 0) > 0 && (
                <SubMenu item={item} count={index} />
              )}{" "}
            </MenuOption>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default Menu;
