import React, { FC, ReactNode, useState } from "react";
import "./Header.module.scss";
import { Link } from "gatsby";
import CustomButton from "../../../common/Button";
import Menu from "../../../common/Menu";
import { MENU_HEADER } from "./const/menu.const";
import logo from "./../../../assets/images/logo.png";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons"; // Import specific icon

const Header: FC<{ children: ReactNode }> = ({ children }) => {
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState<boolean>(false);

  const toggleBtn = () => {
    setIsOpenMobileMenu(!isOpenMobileMenu);
  };

  const menu = MENU_HEADER;

  return (
    <main>
      <div className="header-menu">
        <header className={"header"}>
          <div className="container">
            <Link to={"/"} className="title">
              <img src={logo} alt="Logo Pagosfiable" className="title__logo" />
            </Link>
            <section className="navbar-horizontal">
              <Menu position={"horizontal"} menu={menu.menu}  link={menu.to}/>
            </section>
            <div className="mobile">
              <CustomButton
                icon={!isOpenMobileMenu ? faBars : faXmark}
                onClick={toggleBtn}
              />
            </div>
          </div>
        </header>
        <section
          className={`navbar-vertical ${
            isOpenMobileMenu ? "navbar-vertical--active" : ""
          }`}
        >
          <Menu position={"vertical"} menu={menu.menu} />
        </section>
      </div>
      {children}
    </main>
  );
};

export default Header;
