exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-docs-first-step-index-tsx": () => import("./../../../src/pages/Docs/FirstStep/index.tsx" /* webpackChunkName: "component---src-pages-docs-first-step-index-tsx" */),
  "component---src-pages-docs-index-tsx": () => import("./../../../src/pages/Docs/index.tsx" /* webpackChunkName: "component---src-pages-docs-index-tsx" */),
  "component---src-pages-docs-integration-index-tsx": () => import("./../../../src/pages/Docs/Integration/index.tsx" /* webpackChunkName: "component---src-pages-docs-integration-index-tsx" */),
  "component---src-pages-docs-test-card-index-tsx": () => import("./../../../src/pages/Docs/TestCard/index.tsx" /* webpackChunkName: "component---src-pages-docs-test-card-index-tsx" */),
  "component---src-pages-docs-tranference-index-tsx": () => import("./../../../src/pages/Docs/Tranference/index.tsx" /* webpackChunkName: "component---src-pages-docs-tranference-index-tsx" */),
  "component---src-pages-home-components-features-index-tsx": () => import("./../../../src/pages/Home/components/Features/index.tsx" /* webpackChunkName: "component---src-pages-home-components-features-index-tsx" */),
  "component---src-pages-home-components-form-index-tsx": () => import("./../../../src/pages/Home/components/Form/index.tsx" /* webpackChunkName: "component---src-pages-home-components-form-index-tsx" */),
  "component---src-pages-home-components-hero-index-tsx": () => import("./../../../src/pages/Home/components/Hero/index.tsx" /* webpackChunkName: "component---src-pages-home-components-hero-index-tsx" */),
  "component---src-pages-home-components-intregrations-index-tsx": () => import("./../../../src/pages/Home/components/Intregrations/index.tsx" /* webpackChunkName: "component---src-pages-home-components-intregrations-index-tsx" */),
  "component---src-pages-home-components-join-index-tsx": () => import("./../../../src/pages/Home/components/Join/index.tsx" /* webpackChunkName: "component---src-pages-home-components-join-index-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/Home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

