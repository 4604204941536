import React, { FC } from "react";
import { MenuOptionProps } from "../../../hooks/Menu/menu.prop";
import { Link } from "gatsby";
import "./MenuOption.module.scss";

const MenuOption: FC<MenuOptionProps> = ({ item, children }) => {
  return (
    <li className="option">
      <span className="action">
        {item.to && (
          <Link className={`action__link ${item.style}`} to={item.to}>
            {item.title}
          </Link>
        )}
        {!item.to && <>{item.title}</>}
        {(item.items?.length ?? 0) > 0 && <div className="icon">v</div>}
      </span>
      {children}
    </li>
  );
};

export default MenuOption;
