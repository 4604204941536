import React, { FC, ReactNode } from "react";
import "./Layout.module.scss";
import Header from "./Header";
import Footer from "./Footer";

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <main className="layout">
      <Header>
        <div className="view-container2">{children}</div>
        <Footer />
      </Header>
    </main>
  );
};

export default Layout;
