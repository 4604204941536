import React, { FC } from "react";
import "./Footer.module.scss";
import Menu from "../../../common/Menu";
import { MENU_FOOTER } from "./const/menu.const";
import logo from "./../../../assets/images/logo2.png";

const Footer: FC = () => {
  const menus = MENU_FOOTER;

  return (
    <main className="content">
      <footer className="footer">
        <div className="container container-footer">
          <div className="logo-section">
            <img
              className="logo-section__img"
              src={logo}
              alt="Logo Pagos Fiable"
            />
            <p>© 2024 PAGOSFIABLE SA DE CV. Todos los derechos reservados</p>
          </div>
          {menus.map((menu, index) => (
            <div className="content-section" key={index}>
              <h4 className="section-title">{menu.title}</h4>
              <Menu position={menu.position} menu={menu.menu} />
            </div>
          ))}
        </div>
      </footer>
    </main>
  );
};

export default Footer;
